import React from 'react';
import inputWindow from '../icons/inputWindow.jpg';
import guideWindow from '../icons/guideWindow.jpg';
import summaryWindow from '../icons/summaryWindow.jpg';
import praveensir from '../icons/Dr.Madas.jpg'
import RajSir from '../icons/RajSir.webp'
import pravin2 from '../icons/pravin2.jpeg'

function AboutUsPraveen() {
    return (
        <div className="jumbotron-mv">
        <div className="youtube-player-mv">            
            <img 
                width="560" 
                height="315" 
                src={praveensir} 
                alt="YouTube video placeholder" 
                title="YouTube video player" 
                style={{overflow:'hidden', maxWidth:'100%', maxHeight:'100%', objectFit: 'contain'}}
            />
        </div>

            <div className="heading">
                <h2 className="slider__caption"><strong>Dr.Eshwar Madas</strong></h2>
                <h5><strong>Founder & Group Chairman</strong></h5>
            <div className="content">            
                With an extensive background in medical  administration, clinical practice, and progressive healthcare  
                technologies, Dr. Eshwar Madas is a distinguished figure in the  healthcare sector. His qualifications include an 
                MBBS, MD in Hospital  Administration, a Postgraduate Diploma in Health Service Management, a  Master of Business 
                Studies in Healthcare, and a Fellowship from the  esteemed Royal Australian College of General Practitioners (FRACGP).
            </div>
            <div className="content"> 
                Dr. Madas's proficiency in his field is built on a  solid foundation of practical experience, having served in 
                multiple  high-profile roles across Australia and New Zealand. He held a position  as a Trainee Registrar in 
                Medical Administration  with The Royal Australasian College of Medical Administrators (RACMA)  and acted as a 
                Regional Medical Director in Victoria. As Medical  Director and Principal General Practitioner at the esteemed 
                Lane Street  Medical Centre in Sydney, he demonstrated exceptional  leadership and an unwavering commitment to 
                patient care.
            </div>
        </div>  
        <div className="content-extra">            
            His commitment to medical education is equally  profound, reflected in his contribution to the development of medical  
            students and international medical graduates. Dr. Madas has been  instrumental in driving health education and 
            awareness  initiatives, positioning him as a strong advocate for continued  learning and patient engagement.<br></br>

            Dr. Madas's interest in innovative technologies has  steered his career toward the fusion of healthcare and 
            technology. He  has established his expertise in telehealth and electronic health  records and demonstrated the 
            transformative potential  of artificial intelligence in healthcare delivery. His experience as a  clinical systems 
            consultant for 17 public hospitals in Northwest  Victoria only underscores his influence, where he played a crucial 
            role  in enhancing healthcare delivery by improving critical  hospital-specific IT systems. He has been pivotal in 
            shaping functional  specifications for diverse hospital-based clinical applications and  successfully implementing 
            health-specific IT projects.
        </div>     
        <div className="content-extra">            
            As the founder and CEO of Madashealth.ai, Dr. Madas  embodies the seamless marriage of healthcare and technology. 
            His  comprehensive experience and academic excellence make him an  authoritative figure in this industry. 
            He is committed to  fostering an innovative and efficient healthcare system, leveraging  artificial intelligence to 
            revolutionize patient care. Dr. Eshwar Madas  is a testament to technology's transformative power in modern  healthcare.
        </div> 
    </div> 
    );
}

export default AboutUsPraveen;
