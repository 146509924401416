import React from 'react';
import inputWindow from '../icons/inputWindow.jpg';
import guideWindow from '../icons/guideWindow.jpg';
import summaryWindow from '../icons/summaryWindow.jpg';
import MadasSir from '../icons/Dr.Madas.webp';
import RajSir from '../icons/RajSir.webp';
import KK from '../icons/KK.JPG';
import keyStethImage from '../icons/AI_team.webp';

function AboutUsTeamDetails() {
    return (
        <div className="jumbotron-mv">
            <div className="youtube-player-mv">
                <img
                    width="560"
                    height="315"
                    src={RajSir}
                    alt="Rajeshwar Reddy Konkisa"
                    title="Rajeshwar Reddy Konkisa"
                    style={{ overflow: 'hidden', maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                />
            </div>
            <div className="heading">
                <h2 className="slider__caption"><strong>Rajeshwar Reddy Konkisa</strong></h2>
                <h5><strong>Chief Technology Officer & Director of AI & Data Science</strong></h5>
                <div className="content">
                    Mr. Rajeshwar Reddy Konkisa is a highly skilled professional with a strong background in statistics, data science, and computer engineering. His educational qualifications include a MicroMasters in Statistics and Data Science from the Massachusetts Institute of Technology and a Post Graduation in Data Science from the Indian Institute of Technology Guwahati. He obtained his Bachelor of Engineering in Computer Technology from Kavikulguru Institute of Technology and Science, Nagpur University, and holds a Computer Engineering Diploma from S.N.M Polytechnic Khammam, India.
                </div>
                <div className="content">
                  Mr. Rajeshwar Reddy Konkisa gained valuable international experience while working in the United States. He contributed his expertise to prominent companies such as Merck in Whitehouse Station, NJ (with HCL America) and Prudential Financials in Shelton, CT. Throughout his career, he has also worked for multinational corporations including GE Capital, WIPRO Infotech, HSBC GLT, and HCL Technologies India.
                </div>
            </div>
            <div className="content-extra">
                With a career spanning 20 years, Mr. Rajeshwar Reddy Konkisa has developed a strong focus in the fields of Data Science, Business Intelligence, and Data Warehousing. He possesses extensive knowledge and practical experience with various BI/DW technologies, including MSBI, IBM COGNOS, and Business Objects. In the past 12 years, he has worked with multiple Australian clients, collaborating with organizations such as Western Power, Integral Energy, Westpac (with HCL Australia), Department of Veterans’ Affairs, Primary Health Care, NSW Rail Corp (with Mahindra Satyam), Nuance Group, Optus Sydney (with Tech Mahindra), Department of Finance New South Wales, Department of Defence project (with Avanade Australia), Australian Red Cross Blood Service, Department of Communities and Justice, and the Australian Department of Health.
            </div>
            <div className="content-extra">
                His expertise lies in Python programming, Machine Learning, Deep Learning, Image Classification, Anomaly Detection, and Prediction. He possesses a versatile skill set that includes Data Extract, Transform, and Loading (ETL) using tools such as Talend for Big Data, IBM Cognos Tools, TM1, Power BI, Python, Machine Learning/Deep Learning, AWS SageMaker, Machine Learning on AWS, TensorFlow, Snowflake, Cerner Millennium, and the Hadoop toolset, including Spark/Scala, Pyspark, Hive, and Kafka.
            </div>

            <div className="youtube-player-mv">
                <img
                    width="560"
                    height="315"
                    src={KK}
                    alt="Kanwaljit Singh Kareer (KK)"
                    title="Kanwaljit Singh Kareer (KK)"
                    style={{ overflow: 'hidden', maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                />
            </div>
            <div className="heading">
                <h2 className="slider__caption"><strong>Kanwaljit Singh Kareer (KK)</strong></h2>
                <h5><strong>Country Head - India</strong></h5>
                <div className="content">India and Regional Partner for Indonesia, the Philippines, Malaysia, Vietnam, and Papua New Guinea</div>
                <div className="content">
                    Kanwaljit is a seasoned technology leader with extensive expertise in developing innovative products and delivering transformative solutions across AI/ML, Cloud & Edge Computing, Networks, Cybersecurity, Blockchain, Data Science, IoT, Digital, Biotechnology, and Health Tech. He has successfully driven digital innovation, implemented advanced technologies, and optimized IT operations for leading corporations globally.
                </div>
                <div className="content">
                    With over 26 years of experience, Kanwaljit has a proven ability to manage cross-functional teams, foster strategic partnerships, and seamlessly integrate technologies to improve operational efficiency, security, and customer experience. His leadership spans a broad spectrum of roles, including innovation, product development, architecture, and application design. He has consulted with clients, executives, and sales teams to deliver cutting-edge solutions across both cloud and on-premises environments.
                </div>
            </div>
            <div className="content-extra">
                Kanwaljit’s iterative approach to leveraging data and technology has enabled him to build and manage comprehensive enterprise platforms that drive business outcomes. His focus on enhancing productivity, time to market, engagement, and quality has delivered tangible results for clients in the USA, Hong Kong, the UK, Dubai, Singapore, Malaysia, Vietnam, the Philippines, Indonesia, China, and India.
            </div>
        </div>
    );
}

export default AboutUsTeamDetails;
