import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import digsan from '../icons/dig-sanj2.webp';

function InternsPresentPast() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 10000, // Adjust the speed as per your requirement 2000 miliseconds
  };

  return (
    <div className="testimonials">
        <div className="step-edu-specialist-main">
          <div className="slide-content-spec">
            <div className="logo-block-spec"><img className="gridlogo-spec" src={digsan} alt="Document Accuracy"></img></div>
            <div className="text-block-spec">                          
              <ul><h5><strong>Digital Sanjeevani:</strong> Your 24/7 Healthcare Buddy</h5>
                Imagine having a virtual doctor at your fingertips, ready to assist you round the clock. 
                Digital Sanjeevani is a user-friendly mobile app and hotline that listens to your health concerns, 
                offers immediate help for emergencies, and provides reassurance for non-urgent issues.<br></br><br></br>
                Best of all, if publicly funded, this service could be available to you for free. 
                Say goodbye to unnecessary trips to the doctor, reduce anxiety, and protect yourself from unwarranted medical 
                expenses. With research-backed success stories, Digital Sanjeevani is revolutionizing healthcare accessibility, 
                offering guidance, and peace of mind whenever you need it.
              </ul>
            </div>
          </div>
        </div>               
    </div>
  );
}

export default InternsPresentPast;
