import React from 'react';
import keyStethImage from '../icons/healthcare-prof.avif'   // Import the image based on the given directory structure

function JumboProject() {
  return (
    <div className="jumbotron-mv-medispeak">
        <div className="heading"><h5><strong>Empowering Healthcare Professionals</strong></h5>
            <div className="content">
              At MadasHealth, we're redefining the landscape of healthcare through innovative AI technology.
              Our cutting-edge products are designed to revolutionize the way healthcare professionals work. 
              With MadasHealth, practitioners gain invaluable time, allowing them to focus on what truly matters: patient care. 
              Our AI solutions streamline workflows, enhance diagnostic accuracy, and elevate treatment efficiency. 
              Experience the future of healthcare with MadasHealth – where technology meets compassion, and efficiency meets care.
             </div>
        </div>
        <div className="youtube-player">            
            <img 
                width="560" 
                height="315" 
                src={keyStethImage} 
                alt="YouTube video placeholder" 
                title="YouTube video player" 
                style={{overflow:'hidden', maxWidth:'100%', maxHeight:'100%', objectFit: 'contain'}}
            />
        </div>
    </div>  
  );
}

export default JumboProject;

