import React from 'react';
import keyStethImage from '../icons/GTechnology-Logo.jpg'

function AboutUsJumbo() {
  return (
    <div className="jumbotron-home-medispeak">
            <div className="youtube-player">            
              <img 
                  width="560" 
                  height="315" 
                  src={keyStethImage} 
                  alt="YouTube video placeholder" 
                  title="YouTube video player" 
                  style={{overflow:'hidden', maxWidth:'100%', maxHeight:'100%', objectFit: 'contain'}}
              />
              <div className="content-home"><h5>Emerging from the dynamic pulse of Sydney's technology sector, MadasHealthAI distinguishes itself not just as a 
            technological breakthrough but as a revolutionary partner in the field of medicine. Established in 2023, this 
            groundbreaking entity combines the intuitive brilliance of human ingenuity with advanced artificial intelligence, 
            breaking down long-standing obstacles in the dissemination of medical knowledge. <br></br><br></br>
            MadasHealthAI excels in synthesizing vast amounts of medical information, providing swift, pinpointed insights. As a result, it stands as a guiding light 
            in the contemporary medical landscape, crafting state-of-the-art products that redefine the standards of medical 
            education and practice.</h5></div>
            </div>            
        </div>
  );
}

export default AboutUsJumbo;
