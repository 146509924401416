import React from 'react';

function JumbotronHomeECG() {
  return (
    <div className="jumbotron-home-goals-ecg">
        <div className="heading"><strong>Key Features of ECG Deciphered</strong>
        </div>
        
    </div>        
  );
}

export default JumbotronHomeECG;

