import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import medicalImagry from '../icons/comm.webp';
import voiceInte from '../icons/bridgeGaps.webp';
import revClin from '../icons/aidriven.webp';
import medCommu from '../icons/ecgehr.webp';
import surgery from '../icons/equip.webp';

function MeetSmartSliderECG() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  return (
    <div className="testimonials">
      <Slider {...settings}>        
        <div className="step-edu-specialist-main">
            <div className="slide-content-spec">
              <div className="logo-block-spec"><img className="gridlogo-spec-slider" src={voiceInte} alt="Voice-Driven Clinical Documentation"></img></div>
              <div className="text-block-spec">              
                <p><h1>Bridging Healthcare Gaps:</h1> •	Revolutionizing Rural Healthcare with Advanced ECG Services<br></br> •	Accessible Cardiac Care for Every Corner of the World</p>
              </div>
            </div>
        </div>
        <div className="step-edu-specialist-main">
            <div className="slide-content-spec">
              <div className="logo-block-spec"><img className="gridlogo-spec-slider" src={revClin} alt="Voice-Driven Clinical Documentation"></img></div>
              <div className="text-block-spec">              
                <p><h1>AI-Driven Accuracy in ECG Readings:</h1> Enhancing ECG interpretation with AI, providing precise and reliable cardiac assessments.</p>
              </div>
            </div>
        </div>
        <div className="step-edu-specialist-main">
            <div className="slide-content-spec">
              <div className="logo-block-spec"><img className="gridlogo-spec-slider" src={medCommu} alt="Voice-Driven Clinical Documentation"></img></div>
              <div className="text-block-spec">              
                <p><h1>ECG and EHR: Synchronized for Superior Care:</h1> Seamlessly integrating ECG data with electronic health records for efficient and comprehensive patient care.</p>
              </div>
            </div>
        </div>
        <div className="step-edu-specialist-main">
            <div className="slide-content-spec">
              <div className="logo-block-spec"><img className="gridlogo-spec-slider" src={surgery} alt="Voice-Driven Clinical Documentation"></img></div>
              <div className="text-block-spec">              
                <p><h1>Equipping Medics with AI-Powered ECG Training:</h1> Advanced training tools for healthcare professionals to master ECG interpretation through AI-enhanced simulations.</p>
              </div>
            </div>
        </div>
        <div className="step-edu-specialist-main">
            <div className="slide-content-spec">
              <div className="logo-block-spec"><img className="gridlogo-spec-slider" src={medicalImagry} alt="Voice-Driven Clinical Documentation"></img></div>
              <div className="text-block-spec">              
                <p><h2>Early Detection, Healthier Communities:</h2> Organizing community ECG screenings to promote early detection and prevention of heart diseases.</p>
              </div>
            </div>
        </div>        
      </Slider>
    </div>
  );
}

export default MeetSmartSliderECG;
