import React from 'react';
import logoImage from '../icons/GTechnology-Logo.jpg';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className="jumbotron-aboutUs-footer">          
               
        <div className="contact-footer">              
            <div className="content-answer-footer">
            <label className="Contact-values"><i className="fas fa-map-marker-alt"></i> Address(Australia)</label>
            <h6 className="values">Madas Health, Bella Vista, New South Wales, Australia</h6>

            {/*<label className="Contact-values"><i className="fas fa-map-marker-alt"></i> Address(India)</label>
            <h6 className="values">GTechnologies Pty Ltd, 1-5-1092/32/201, Sai Nilayam Apartments, Mangapuram, Old Alwal, Secundrabad, Medchal, Telangana, India.</h6>

            <label className="Contact-values"><i className="fas fa-mobile-alt"></i> Australian Company Number (ACN)</label>
            <h6 className="values">+61 629 175 211</h6>*/}

            <label className="Contact-values"><i className="fas fa-mobile-alt"></i> Contact Number</label>
            <h6 className="values">+61426244089</h6>

            <label className="Contact-values"><i className="fas fa-envelope"></i> Email</label>
            <h6 className="values">admin@madashealth.ai</h6>

            <label className="Contact-values"><i className="fas fa-link"></i><Link to="/privacy-policy" ><h6>Privacy Policy</h6></Link></label>            
            </div>
        </div>

        
    </div>    
  );
}

export default Footer;
