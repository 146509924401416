import React from 'react';

function SignupComponent() {
  // Handler to open the form link in a new tab
  const handleButtonClick = () => {
    window.open('https://forms.office.com/r/s9gfj4PKvk', '_blank');
  };

  return (
    <div className="signup-comp">
        <label className="question-working-signup"><h5><strong>Call today to schedule an appointment:</strong></h5>
        <h5>+61426244089</h5>
        <label></label>
        <h5> Hours: 
          <h5></h5>
          <h5>Monday - Friday: 8AM–8PM</h5>
          <h5>Saturday - Sunday: 8AM–6PM</h5>
        </h5>
        </label>        
        <div className="join-button">
            <button className="signup-button" onClick={handleButtonClick}>REQUEST YOUR PERSONALIZED INTEREST</button>
        </div>
    </div>
  );
}

export default SignupComponent;
