import React from 'react';
import fontPage from '../icons/font_page.jpg';  // Import the image based on the given directory structure

function Jumbotron() {
  return (
    <div className="jumbotron-mv-main">
          <div className="heading"><strong>MADAS HEALTH ARTIFICIAL INTELLIGENCE (AI)</strong>
              <div className="content"><h5><strong>Empowering Healthcare Professionals and Patients with Cutting-Edge AI Applications from Madas Telehealth Pty Ltd.</strong></h5> </div>
        </div>
    </div>        
  );
}

export default Jumbotron;

