import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

// Importing components
import Navbar from './assets/components/Navbar.js';
import Jumbotron from './assets/components/Jumbotron.js';
import InformationSection from './assets/components/InfoSection.js';
import Testimonials from './assets/components/Testimonials.js';
import SignupComponent from './assets/components/SignUpComponent.js';
import Footer from './assets/components/Footer.js';
import Copyright from './assets/components/CopyRights.js';
import ScrollToTop from './assets/components/scrollingFunction.js'
import JumbotronHomeMedispeak from './assets/components/JumbotronHomeMedispeak.js';
// Import all pages here
import AboutUsPage from './AboutUsPage.js'
import PrivacyPage from './PrivacyPolicyPage.js';  
import MedicalEducationPage from './MedicalEducationPage.js';
import ProductsServices from './ProductsServices.js';
import CLientsPage from './CLientsPage.js';
import ProjectsPage from './ProjectsPage.js';
import CollaborationPage from './CollaborationPage.js';
import InternsPage from './InternsPage.js';
import JumbotronHome from './assets/components/JumbotronHome.js'
import OthersPage from './OthersPage.js'
import ECGDecipher from './ECGDecipher.js'

// Importing CSS
import './assets/styles/header.css';  // assuming the styles folder is inside assets
import './assets/styles/jumbotron.css';
import './assets/styles/information-sections.css';
import './assets/styles/impacts-sections.css';
import './assets/styles/textimonials.css';
import './assets/styles/signup-comp.css';
import './assets/styles/footer.css';

function App() {
  return (
    <Router>
      <ScrollToTop /> 
      <div className="App">
        <Routes>                   
          <Route path="/privacy-policy" element={<PrivacyPage />} /> {/*Redirects like this*/}
          <Route path="/about-us" element={<AboutUsPage />} /> {/*Redirects like this*/}
          <Route path="/clients" element={<CLientsPage />} /> {/*Redirects like this*/}
          <Route path="/healthprofessionals" element={<ProjectsPage />} /> {/*Redirects like this*/}
          <Route path="/collaborations" element={<CollaborationPage />} /> {/*Redirects like this*/}
          <Route path="/patients" element={<InternsPage />} /> {/*Redirects like this*/}
          <Route path="/others" element={<OthersPage />} /> {/*Redirects like this*/}
          <Route path="/ECGDeciphered" element={<ECGDecipher />} /> {/*Redirects like this*/}
          <Route path="/" element={<HomePage />} />
        </Routes>
      </div>
    </Router>
  );
}

function HomePage() {
  return (
    <>
      <Navbar />
      <JumbotronHome />
      <JumbotronHomeMedispeak />
      {/*<JumbotronCyber />*/}
      <InformationSection />
      <SignupComponent />
      <Footer />
      <Copyright />
    </>
  );
}

export default App;