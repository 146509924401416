import React from 'react';

// Importing components
import Navbar from './assets/components/Navbar.js';
import SignupComponent from './assets/components/SignUpComponent.js';
import Footer from './assets/components/Footer.js';
import Copyright from './assets/components/CopyRights.js';
import MeetSmartSliderECG from './assets/components/MeetSmartSliderECG.js';
import JumbotronHomeECG from './assets/components/JumbotronHomeECG.js';

// Importing CSS
import './assets/styles/header.css';  // assuming the styles folder is inside assets
import './assets/styles/jumbotron.css';
import './assets/styles/information-sections.css';
import './assets/styles/impacts-sections.css';
import './assets/styles/textimonials.css';
import './assets/styles/signup-comp.css';
import './assets/styles/footer.css';

function ECGDecipher() {
  return (
    <div className="App">
      <Navbar />
      <JumbotronHomeECG />
      <MeetSmartSliderECG />
      <SignupComponent />
      <Footer />
      <Copyright />
    </div>
  );
}

export default ECGDecipher;
