import React from 'react';
import keyStethImage from '../icons/mision.jpg'

function AboutUSMissionVisionTwo() {
  return (
    <div className="jumbotron-mv">      

        <div className="heading"><h5>Our Mission</h5>
            <div className="content">            
            MadasHealthAI is passionately committed to driving significant transformation in the healthcare sector. 
            Our mission hinges on leveraging data-driven insights to initiate impactful changes. We believe in the power of a 
            values-driven organizational culture to guide our efforts in reshaping healthcare practices. Our approach combines 
            advanced analytics with a deep understanding of healthcare dynamics, aiming to enhance patient care and overall 
            industry efficiency. At the heart of our mission is the aspiration to innovate and elevate the standards of 
            healthcare, making a lasting, positive difference in the lives of both healthcare providers and patients.
            </div>
        </div>   

        <div className="youtube-player-mv">            
            <img 
                width="560" 
                height="315" 
                src={keyStethImage} 
                alt="YouTube video placeholder" 
                title="YouTube video player" 
                style={{overflow:'hidden', maxWidth:'100%', maxHeight:'100%', objectFit: 'contain'}}
            />
        </div>     
    </div>
        
  );
}

export default AboutUSMissionVisionTwo;
