import React from 'react';
import keyStethImage from '../icons/team-gtech.webp'

function AboutUsTeam() {
  return (
    <div className="jumbotron-ourTeam">   
        <div className="heading"><h5>The professional ensemble at Madas Health AI encompasses</h5>
            <div className="content">
            At Madas Health AI, our strength lies in the diversity and expertise of our team. This dynamic group is composed of 
            professionals from various disciplines, each contributing unique skills and perspectives that drive our collective 
            mission forward. Leading the charge is our Chief Data Scientist, a visionary in harnessing data for healthcare 
            advancements. Our AI Specialists are at the forefront of artificial intelligence, pushing the boundaries of what's 
            possible in medical technology. Cybersecurity Consultants ensure the utmost protection of sensitive data, a 
            cornerstone in the digital healthcare domain. Our Software Developers and Engineers are the creative problem-solvers, 
            constructing robust and innovative software solutions. Quality Analysts meticulously uphold our high standards, 
            ensuring our products and services meet rigorous quality benchmarks. Finally, the Project Manager orchestrates this 
            symphony of talent, ensuring that projects are executed efficiently and effectively. Together, this talented ensemble 
            is the driving force behind Madas Health AI's pursuit of excellence in healthcare innovation.
            </div>
        </div>  
        <div className="youtube-player">            
            <img 
                width="560" 
                height="315" 
                src={keyStethImage} 
                alt="YouTube video placeholder" 
                title="YouTube video player" 
                style={{overflow:'hidden', maxWidth:'100%', maxHeight:'100%', objectFit: 'contain'}}
            />
        </div>                      
    </div>    
  );
}

export default AboutUsTeam;
