import React from 'react';
import wordCloud from '../icons/Logo-medispeak.jpg';

function JumbotronHomeMedispeak() {
  return (
    <div className="jumbotron-home-medispeak">
            <div className="youtube-player">            
              <img 
                  width="560" 
                  height="315" 
                  src={wordCloud} 
                  alt="YouTube video placeholder" 
                  title="YouTube video player" 
                  style={{overflow:'hidden', maxWidth:'100%', maxHeight:'100%', objectFit: 'contain'}}
              />
              <div className="content-home"><h5>Medispeak AI Clinical Trials:<br></br><br></br>
                Trial Period: July-December 2023 in Sydney, Australia.<br></br>
                Participants: 50 General Practitioners and 12 International Medical Graduates.<br></br>
                Methodology: Tested in real-time clinical scenarios.<br></br><br></br>

                Key Findings:<br></br>

                Time Savings: An average of 7.5 minutes saved per consultation.<br></br>
                Diagnostic Accuracy: Above 95% success rate.<br></br>
                Economic Benefits: Significant time savings, improved patient care, and potential for increased efficiency.</h5></div>
            </div>            
        </div>       
     
  );
}

export default JumbotronHomeMedispeak;

