import React from 'react';
import fontPage from '../icons/home-page.webp';  // Import the image based on the given directory structure

function JumbotronHome() {
  return (
    <div className="jumbotron-home">
        <div className="heading-home"><strong>MADAS HEALTH ARTIFICIAL INTELLIGENCE (AI)</strong>
              <div className="content"><h5><strong>Empowering Healthcare Professionals and Patients with Cutting-Edge AI Applications from Madas Telehealth Pty Ltd.</strong></h5> </div>
            <div className="youtube-player">            
            <img 
                width="900" 
                height="500" 
                src={fontPage} 
                alt="YouTube video placeholder" 
                title="YouTube video player" 
                style={{overflow:'hidden', maxWidth:'100%', maxHeight:'100%', objectFit: 'contain'}}
            />
        </div>
        </div>
    </div>        
  );
}

export default JumbotronHome;

