import React from 'react';
import keyStethImage from '../icons/vision.png'

function AboutUSMissionVision() {
  return (
    <div className="jumbotron-mv">
        <div className="youtube-player-mv">            
            <img 
                width="560" 
                height="315" 
                src={keyStethImage} 
                alt="YouTube video placeholder" 
                title="YouTube video player" 
                style={{overflow:'hidden', maxWidth:'100%', maxHeight:'100%', objectFit: 'contain'}}
            />
        </div>

        <div className="heading"><h5>Our Vision</h5>
            <div className="content">            
            MadasHealthAI is dedicated to revolutionizing healthcare through the development of cutting-edge software, 
            services, and data solutions. Our goal is to empower healthcare providers to optimize patient health with 
            evidence-based approaches, advanced analytics, and innovative methods. We believe in transforming healthcare 
            delivery by enhancing the quality of care, enabling providers to deliver more personalized, effective treatments. 
            Our solutions are designed to streamline healthcare operations, improve diagnostics, and elevate treatment protocols, 
            ultimately making healthcare more efficient and accessible. At MadasHealthAI, we are committed to setting new 
            standards in healthcare, contributing to a healthier and more sustainable future for all.
            </div>
        </div>        
    </div>
        
  );
}

export default AboutUSMissionVision;
