import React from 'react';
import { useNavigate } from 'react-router-dom';
import development from '../icons/background-about-us.avif';
import consulting from '../icons/dig-sanjeevani.jpg';
import techsupport from '../icons/pathodx.jpg';
import training from '../icons/assistcare.jpg';

function InformationSection() {
    const history = useNavigate();

    const goToAboutUs = () => {
        history("/about-us");
    };

    const goToHealthcareProfessionals = () => {
        history("/healthprofessionals");
    };

    const goToPatients = () => {
        history("/patients");
    };

    const goToCollaborationsAndClients = () => {
        history("/collaborations");
    };

  return (
    <div className="information-sections">
        {/*<label className="question-working">Our Projects</label>
        <div className="content-answer">
            Our goal is to create software, services, and data solutions that help healthcare 
            providers optimize the health of each patient. With the right evidence-based approaches, analytics, and methods, 
            you believe that providers can dramatically improve the quality of care they deliver to their patients, ultimately 
            transforming healthcare for the better.
        </div>*/}
        
        <div className="step-grid">
            <div className="step-home" style={{ backgroundImage: `url(${development})` }} onClick={goToAboutUs}>                               
                <label className="question-working-sub">ABOUT US</label> 
                <div className="content-answer">Know More about Us and our Team</div>               
            </div>
            
            <div className="step-home" style={{ backgroundImage: `url(${consulting})` }} onClick={goToHealthcareProfessionals}>
                <label className="question-working-sub">HEALTHCARE PROFESSIONALS</label>  
                <div className="content-answer">See how we help Healthcare Professional excel in their day to day work</div>              
            </div>
            
            <div className="step-home" style={{ backgroundImage: `url(${techsupport})` }} onClick={goToPatients}>                
                <label className="question-working-sub">PATIENTS </label>   
                <div className="content-answer">See how our products make the life of patients easy</div>             
            </div>
            
            <div className="step-home" style={{ backgroundImage: `url(${training})` }} onClick={goToCollaborationsAndClients}>
                <label className="question-working-sub">COLLABORATIONS AND CLIENTS</label> 
                <div className="content-answer">Know about our collaborations and clients</div>               
            </div>         
           
        </div>
    </div>
  );
}

export default InformationSection;
